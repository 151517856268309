import React from "react"
import Layout from "../../components/storyLayout"
import SEO from "../../components/seo"

const StoryPost = () => (
  <Layout storySrc="https://www.youtube.com/embed/L_mao-MZElk">
    <SEO title="Benefits of Bible Groups - Story" />
  </Layout>
)

export default StoryPost
